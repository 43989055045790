import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timi-workstation-container" }
const _hoisted_2 = { class: "form-layout" }
const _hoisted_3 = { class: "timi-form-item col-6" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "timi-form-item col-6" }
const _hoisted_6 = { class: "timi-form-item col-6" }
const _hoisted_7 = { class: "timi-form-item col-6" }
const _hoisted_8 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SelectSingle = _resolveComponent("SelectSingle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      text: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["text", "onOnClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.title')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.title'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event))
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.form.title]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.department')), 1),
          _createVNode(_component_SelectSingle, {
            placeholder: _ctx.$t('placeholder.selectDepartment'),
            item: {},
            options: _ctx.departments,
            value: {},
            disabled: false,
            onOnChange: _cache[1] || (_cache[1] = (item,value)=> _ctx.form.department = value)
          }, null, 8, ["placeholder", "options"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.workarea')), 1),
          _createVNode(_component_SelectSingle, {
            placeholder: _ctx.$t('placeholder.selectWorkarea'),
            item: {},
            options: _ctx.workareas,
            value: {},
            disabled: false,
            onOnChange: _cache[2] || (_cache[2] = (item,value)=> _ctx.form.workarea = value)
          }, null, 8, ["placeholder", "options"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.machine')), 1),
          _createVNode(_component_SelectSingle, {
            placeholder: _ctx.$t('placeholder.selectMachine'),
            item: {},
            options: _ctx.machines,
            value: {},
            disabled: false,
            onOnChange: _cache[3] || (_cache[3] = (item,value)=> _ctx.form.machine = value)
          }, null, 8, ["placeholder", "options"])
        ])
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_8, [
      (!_ctx.editMode)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            text: _ctx.$t('button.addWorkstation'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleCreate
          }, null, 8, ["text", "onOnClick"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            text: _ctx.$t('button.save'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleSave
          }, null, 8, ["text", "onOnClick"]))
    ])
  ]))
}